@import "../scss/framework/tools";

.Main {
    ul {
        display: flex;
        align-items: center;

        @include mq($until: tablet) {
            justify-content: center;
        }

        li {
            &:not(:last-child) {
                @include respSize(margin-right, 36);
            }
        }
    }

    @include mq($until: tablet) {
        position: fixed;
        bottom: 40px;
        right: 0;
        width: 100%;
    }
}

@import "../../scss/framework/tools";

.Main {
    display: flex;
    align-items: center;

    color: $green;
    transition: color .3s;
    background-color: transparent;

    overflow: hidden;

    svg path {
        transition: fill .3s;
    }

    i {
        margin-left: 15px;
    }

    &:hover {
        svg path {
            fill: $black;
        }
    }
}

@import "../../scss/framework/tools";

.Title {
    @extend %DINBold;
    font-size: resp(50);
    line-height: resp(70);

    opacity: 0;

    @include mq($until: tablet) {
        font-size: 3rem;
        line-height: 1.4;

        br {
            display: none;
        }
    }

    @include mq($until: small) {
        font-size: 2rem;
    }

    small {
        margin-top: 35px;
        display: block;

        font-size: resp(20);
        line-height: resp(26);

        @include mq($until: tablet) {
            margin-top: 20px;
            font-size: 1.6rem;
            line-height: 1.3;
        }
    }
}

.Type {
    margin-bottom: 25px;

    color: $green;
    font-size: resp(14);
    line-height: resp(16);
    text-transform: uppercase;

    opacity: 0;

    @include mq($until: tablet) {
        margin-bottom: 15px;
        font-size: 1.5rem;
        line-height: 1.4;
    }

    @include mq($until: small) {
        font-size: 1.3rem;
    }
}

.Source {
    width: 80%;
    position: absolute;
    bottom: resp(100);

    font-size: resp(16);
    color: $green;

    opacity: 0;

    @include mq($until: tablet) {
        position: relative;
        bottom: initial;
        margin-bottom: 10px;

        margin-top: 20px;
        font-size: 1.3rem;
        line-height: 1.4;
    }

    @include mq($until: small) {
        font-size: 1.1rem;
        margin-top: 10px;
    }
}

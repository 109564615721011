@import "../../../scss/framework/tools";

.Main {
    height: 100%;

    > [class*='Container']{
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq($until: tablet) {
            justify-content: center;
        }
    }
}

.TitleBox {
    width: susy-span(6 narrow of 12);
    z-index: 1;

    @include mq($until: tablet) {
        width: 100%;
        margin-top: 20vh;
    }
}

.Plan {
    position: fixed;
    right: 0;
    top: 0;

    display: flex;
    justify-content: flex-end;
    z-index: 0;

    i {
        position: relative;
        top: 0;
        right: 0;
        z-index: -1;
    }

    svg {
        opacity: 0;
        height: 100vh;
        width: auto;

        @include mq($until: tablet) {
            width: 113vw;
            height: auto;
        }
    }
}

.PlanCursor {
    position: fixed;
    right: 0;
    top: 0;

    z-index: 4;

    svg {
        height: 100vh;
        width: auto;

        @include mq($until: tablet) {
            width: 113vw;
            height: auto;
        }
    }

    :global {
        .PlanPath {
            stroke-dasharray: 2000;
            stroke-dashoffset: 2000;
        }

        .PlanButton {
            cursor: pointer;
            opacity: 0;
            transform: translateY(-20px);
        }

        .PlanPointGroup {
            :local {
                animation: PlanPointAnimation 1s linear infinite;
                animation-delay: 1s;
            }
        }

        .PlanPoint, .PlanPointSmall {
            transform: scale(0);
            opacity: 0;
        }
    }
}

@keyframes PlanPointAnimation {
    0% {
        transform: scale(1) translate(-50% -50%) !important;
    }

    50% {
        transform: scale(.8) translate(-50% -50%) !important;
    }

    100% {
        transform: scale(1) translate(-50% -50%) !important;
    }
}

.Glaussian {
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: -1;
}

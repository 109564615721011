/*------------------------------------*\
  #GRID
\*------------------------------------*/
@import "~susy";

$susy: (
        columns: susy-repeat(12),
        gutters: 30px,
        spread: 'narrow',
        container-spread: 'narrow',
        debug: (
                image: show,
                color: grey,
                output: background,
                toggle: top right,
        ),
);

section {
    clear: fix;
}

.container {
    clear: fix;
    position: relative;
    margin: 0 auto;

    box-sizing: border-box;
    max-width: resp(1280);
    height: 100%;
}

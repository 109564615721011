@import "../scss/framework/tools";
@import "../components/Questions/QuestionTitle.module.scss";

.Main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.TitleWrapper {
    position: relative;

    display: flex;
    justify-content: space-between;

    @include mq($until: tablet) {
        flex-direction: column;
    }

    hr {
        content: '';
        position: absolute;
        left: calc(100% + 300px);
        bottom: 0;
        right: -300px;

        height: 3px;
        background-color: $green;
        border: none;
        opacity: 0;
    }
}

.Title {
    @extend .Title;
    opacity: 1;
}

.Advices {
    margin-top: 10px;
    padding-bottom: 70px;

    @include mq($until: tablet) {
        padding-bottom: 40px;
    }

    @include mq($until: small) {
        padding-bottom: 29px;
    }

    li {
        display: flex;
        justify-content: flex-start;

        font-size: resp(26);
        line-height: resp(36);
        @extend %DINBold;

        opacity: 0;

        @include mq($until: tablet) {
            font-size: 1.8rem;
            line-height: 1.2;
        }

        @include mq($until: small) {
            font-size: 1.6rem;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        > i {
            margin-right: 20px;
        }

        span {
            color: $green;
        }
    }
}

.Promise {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 70px;

    @include mq($until: tablet) {
        flex-direction: column;
        align-items: center;

        padding-top: 20px;
    }

    h1 {
        font-size: resp(70);
        line-height: resp(60);

        margin-right: resp(60);

        @include mq($until: tablet) {
            font-size: 4rem;
            line-height: 1;
            margin-right: 0;
            margin-bottom: 20px;
        }

        @include mq($until: small) {
            font-size: 3rem;
        }

        > small {
            font-size: resp(40);
            line-height: 1;

            @include mq($until: tablet) {
                font-size: 2.5rem;
            }

            @include mq($until: small) {
                font-size: 2rem;
            }
        }

        > span {
            display: block;
            text-align: center;
        }
    }

    > a {
        position: relative;
        bottom: initial;
    }
}

@import "../../scss/framework/tools";

.Main {
    position: absolute;
    top: calc(50% - #{resp(100)});
    right: 0;

    transform: translateY(-50%);

    flex-direction: column;
    align-items: flex-start;

    width: susy-span(5 narrow of 12);

    height: 60vh;

    opacity: 0;

    @include mq($until: tablet) {
        position: relative;
        justify-content: flex-start;
        align-items: center;

        top: 10px;
        transform: none;

        width: 100%;
        height: 50vh;
    }
}

.TextScroll {
    margin-bottom: resp(80);
    padding-right: susy-span(.5 wide of 4);
    margin-right: susy-span(.5 wide of 4);

    max-height: 100%;
    overflow-y: scroll;

    flex: 1;

    @include mq($until: tablet) {
        margin-right: 0;
        width: susy-span(4 of 4);
        box-sizing: border-box;

        height: 280px;
    }

    h4 {
        margin-bottom: resp(20);

        @extend %DINBold;
        font-size: resp(26);
        line-height: resp(36);

        @include mq($until: tablet) {
            font-size: 2rem;
            line-height: 1.3;
        }

        span {
            display: inline;
        }
    }

    p {
        @extend %GothamReg;
        line-height: resp(26);

        @include mq($until: tablet) {
            line-height: 2.6rem;
        }

        strong {
            @extend %GothamBold;
        }
    }

    &:global(.green) {
        &::-webkit-scrollbar {
            width: 2px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: $black;        /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
            background-color: $green;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid $green; /* creates padding around scroll thumb */
            height: 1px;
        }
    }

    &:global(.orange) {
        &::-webkit-scrollbar {
            width: 2px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: $black;        /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
            background-color: $orange;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid $orange; /* creates padding around scroll thumb */
            height: 1px;
        }
    }

}

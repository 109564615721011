$white: #ffffff;
$black: #191919;
$bg: $black;

$green: #34DF77;
$orange: #E28B61;

$win: $green;
$loose: $orange;


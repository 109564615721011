@import "../../../scss/framework/tools";

.Main {
    height: 100%;

    > [class*='Container'] {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq($until: tablet) {
            flex-direction: column-reverse;
        }
    }
}

.Content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: tablet) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

.WordsListing {
    width: susy-span(5 wide of 10);

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    opacity: 0;

    @include mq($until: tablet) {
        width: 100%;
        margin-bottom: 20px;

        height: 80px;
    }

    @include mq($until: small) {
        height: 65px;
    }


    > span {
        display: block;
        margin-bottom: resp(10);
        color: $black;
        white-space: nowrap;

        background-color: $green;
        width: resp(270);
        box-sizing: border-box;

        @include mq($until: tablet) {
            margin-right: 5px;
            width: 150px;
        }
        @include mq($until: small) {
            width: 120px;
        }
    }
}

.Sentence {
    position: relative;

    margin-top: resp(60);

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include mq($until: tablet) {
        margin-bottom: 20px;
        align-items: stretch;
    }

    @include mq($from: tablet) {
        &:after, &:before {
            content: '';
            width: 100%;

            position: absolute;
            bottom: resp(20);
            border-bottom: 3px dotted $white;
        }

        &:before {
            bottom: calc(50% + 20px);
        }
    }

    .Word {
        margin-right: resp(10);
        transform: scale(.5);
        opacity: 0;

        @include mq($until: tablet) {
            margin-right: 5px;
        }

        @include mq($until: small) {
            margin-right: 5px;
        }
    }
}

.Word {
    display: block;
    margin-bottom: resp(30);
    margin-right: resp(10);

    padding: resp(20) resp(15);
    border: 3px solid $green;

    font-size: resp(28);
    @extend %DINBold;
    text-align: center;

    transition: width .3s, height .3s;

    @include mq($until: tablet) {
        padding: 6px 8px;
        font-size: 1.6rem;
        line-height: 1.2;
        border-width: 2px;
    }

    @include mq($until: small) {
        font-size: 1.2rem;
    }
}

.Hole {
    width: resp(280);
    height: resp(70);
    margin-right: 10px;

    @include mq($until: tablet) {
        width: 180px;
        height: 35px;
        border: 2px solid $green;
        box-sizing: border-box;
    }

    @include mq($until: small) {
        width: 120px;
        height: 30px;
    }

    .Word {
        opacity: 1;
        transform: scale(1);
        margin-right: 0;

        @include mq($until: tablet) {
            border: none;
        }

        &:not(:empty) {
            background-color: $green;
            color: $black;
        }

    }
}

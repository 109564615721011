@import "../../scss/framework/tools";

.Main {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: $bg;
    z-index: 5;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include mq($until: tablet) {
        justify-content: flex-start;
    }
}

.Disclaimer {
    position: relative;
    top: resp(100);

    font-size: resp(140);
    @extend %DINBold;
    line-height: resp(170);
    color: $green;
    -webkit-text-stroke-color: $green;

    opacity: 0;

    @include mq($until: tablet) {
        top: initial;
        margin-top: 30px;
        font-size: 8rem;
        line-height: 1.3;

        width: 100%;
    }

    @include mq($until: small) {
        font-size: 7rem;
    }

    &:global(.incorrect) {
        color: $orange;
        -webkit-text-stroke-color: $orange;
    }
}

.Image {
    display: flex;
    align-items: flex-end;

    position: relative;
    bottom: 0;
    width: resp(932);

    opacity: 0;
    z-index: -1;

    @include mq($until: tablet) {
        position: absolute;
        right: 0;
        width: 150vw;
    }
}

@import "../../../scss/framework/tools";

.Main {
    height: 100%;

    > [class*='Container']{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.TitleBox {
    width: susy-span(9 narrow of 12);

    @include mq($until: tablet) {
        width: 100%;
    }
}

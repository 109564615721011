@import "../../../scss/framework/tools";

.Main {
    height: 100%;

    [class*='Container'] {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq($until: tablet) {
            flex-direction: column-reverse;
        }

        > div {
            position: relative;
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            flex-direction: column;



            @include mq($until: tablet) {
                justify-content: flex-start;
            }

            &:nth-of-type(2) {
                @include mq($from: tablet) {
                    max-height: resp(800);
                }
                @include mq($until: tablet) {
                    justify-content: flex-end;
                }

            }

        }
    }
}

.DropBoxWrapper {
    position: absolute;
    right: 0;
    top: resp(75);

    opacity: 0;
    transform: scale(0);

    @include mq($until: tablet) {
        margin-top: 40px;
    }

    &:nth-child(1) {
        top: calc(#{resp(75)} + #{resp(30)});
        right: resp(295);

        @include mq($until: tablet) {
            top: respM(40);
            right: initial;
            left: 0;
        }
    }

    &:nth-child(2) {
        right: resp(70);

        @include mq($until: tablet) {
            right: respM(100);
        }
    }

    &:nth-child(3) {
        top: calc(#{resp(75)} + #{resp(210)});

        @include mq($until: tablet) {
            top: respM(50);
        }
    }

    &:global(.show) {
        opacity: 1;
        transform: none !important;
    }
}

.DropBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: resp(235);
    height: resp(235);
    border: 3px solid $green;
    border-radius: 50%;
    background-color: $black;

    @include mq($until: tablet) {
        width: respM(130);
        height: respM(130);
        border-width: 2px;
    }

    span {
        margin-top: resp(20);
        display: block;
        text-align: center;

        font-size: resp(20);

        @include mq($until: tablet) {
            margin-top: 10px;
            font-size: 1.3rem;
            line-height: 1.1;
        }
    }

    strong {
        color: $green;
        @extend %DINBold;
    }

    .Coin {
        opacity: 1;
    }
}

.DropHole, .CoinBlock {
    display: block;

    background-color: black;

    width: resp(75);
    height: resp(75);
    border-radius: 50%;

    @include mq($until: tablet) {
        width: respM(50);
        height: respM(50);
        border-width: 2px;
    }
}

.CoinsWrapper {
    position: relative;
    z-index: 2;

    @include mq($until: tablet) {
        display: flex;
        padding-bottom: 20px;

        > div {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    @include mq($from: tablet) {
        width: resp(365);
        height: resp(300);

        position: absolute;
        top: resp(430);
        right: resp(275);

        > div {

            position: absolute;
            top: 0;
            right: 0;


            &:nth-of-type(1) {
                top: resp(100);
            }

            &:nth-of-type(2) {
                right: resp(85);
            }

            &:nth-of-type(3) {
                top: resp(130);
                right: resp(150);
            }

            &:nth-of-type(4) {
                top: resp(40);
                right: resp(245);
            }

            &:nth-of-type(5) {
                top: resp(175);
                right: resp(285);
            }
        }
    }
}

.Coin {
    display: flex;
    align-items: center;
    justify-content: center;

    width: resp(75);
    height: resp(75);
    border-radius: 50%;
    border: 3px solid $white;

    background-color: black;

    font-size: resp(30);
    @extend %DINBold;

    opacity: 0;

    &:global(.show) {
        opacity: 1;
    }

    @include mq($until: tablet) {
        width: respM(50);
        height: respM(50);
        border-width: 2px;

        font-size: 1.6rem;
    }
}

.Validate {
    bottom: resp(100);
}

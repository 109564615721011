@import "../../scss/framework/tools";

.Global {
    position: relative;

    @include respSize(padding-left, 50, 20);
    @include respSize(padding-right, 50, 20);
    padding-top: resp(15);
    padding-bottom: resp(15);

    @extend %GothamBold;
    font-size: resp(20);

    border: 3px solid $green;
    background-color: $green;

    overflow: hidden;

    @include mq($until: tablet) {
        font-size: 2rem;
        padding-top: 14px;
        padding-bottom: 15px;
        border-width: 2px;
    }

    @include mq($until: small) {
        font-size: 1.6rem;
    }

    &:global(.orange) {
        background-color: $orange;
        border-color: $orange;
    }

    span {
        position: relative;
        z-index: 0;
    }

    &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 100%;

        width: 100%;
        height: 200%;

        background-color: $green;

        transition: top .3s;

        z-index: 0;
    }

    &:hover {
        color: $black;

        &:before {
            top: -100%;
        }
    }
}


.Validate {
    position: absolute;
    right: resp(45);

    display: flex;
    align-items: center;
    justify-content: center;

    @extend %GothamBold;
    font-size: resp(20);
    color: $black;

    width: resp(130);
    height: resp(130);
    background-color: $green;

    border-radius: 100%;
    cursor: pointer;

    transition: opacity .3s, transform .3s;

    @include mq($until: tablet) {
        font-size: 20px;
        width: 100px;
        height: 100px;
        position: fixed;
        bottom: 40px;
        right: 40px;
    }

}

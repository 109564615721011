@import "../scss/framework/tools";

.Main {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: baseline;

    text-indent: 0;
}

.Title {
    margin-bottom: resp(40);

    display: flex;
    flex-direction: column;

    @include mq($until: tablet) {
        text-align: center;

        margin-bottom: 60px;
    }

    > span {
        opacity: 0;
    }

    .Glitch {
        position: relative;
        height: 147px;
        vertical-align: baseline;
        display: inline-block;
        text-indent: 0;

        :local {
            animation: glitch-skew 1s infinite linear alternate-reverse;
        }

        &:before {
            @include glitchCopy();
            left: 2px;
            text-shadow: -2px 0 #ff00c1;
            clip: rect(44px, 450px, 56px, 0);

            :local {
                animation: glitch-anim 5s infinite linear alternate-reverse;
            }
        }

        &:after {
            @include glitchCopy();
            left: -2px;
            text-shadow: -2px 0 $green, 2px 2px #ff00c1;

            :local {
                animation: glitch-anim2 1s infinite linear alternate-reverse;
            }
        }

    }
}

@keyframes glitch-anim {
    $steps: 10;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(147)+px, 9999px, random(147)+px, 0);
            transform: skew((random(147) / 50) + deg);
        }
    }
}

// Same deal, just duplicated for ultra randomness.
@keyframes glitch-anim2 {
    $steps: 10;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(147)+px, 9999px, random(147)+px, 0);
            transform: skew((random(147) / 50) + deg);
        }
    }
}

// Does the same deal as before, but now only skews. Used for main text.
@keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(1) - 5) + deg);
        }
    }
}

.ButtonBox {
    opacity: 0;

    display: flex;

    font-size: resp(24);
    line-height: resp(34);

    @include mq($until: tablet) {
        flex-direction: column;
        align-items: center;

        font-size: 2rem;
        line-height: 1.3;
    }

    p {
        @include respSize(margin-right, 45);

        text-align: right;

        @include mq($until: tablet) {
            margin-right: 0;
            margin-bottom: 30px;
            text-align: center;
        }
    }
}

.Logo {
    @include mq($until: tablet) {
        width: 120px;
    }
}

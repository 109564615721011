@import "../../../scss/framework/tools";

.Main {
    position: relative;

    display: inline-block;
    min-width: resp(210);

    color: $green;

    cursor: pointer;

    @include mq($until: tablet) {
        min-width: 120px;
    }

    > span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        line-height: 1.2;

        border-bottom: 4px solid $green;
        width: 100%;

        @include mq($until: tablet) {
            border-width: 2px;
        }
    }

    svg {
        width: resp(24);
        height: auto;
    }
}

.Options {
    position: absolute;
    top: calc(100% - #{resp(4)});
    left: calc(#{resp(-15)} - #{resp(4)});

    background-color: $black;
    width: calc(100% + #{resp(40)});
    z-index: 3;

    border: 4px solid $green;

    @include mq($until: tablet) {
        width: calc(100% + 50px);
        border-width: 2px;
    }


    li {
        padding: resp(5) resp(15);

        &:not(:last-child) {
            border-bottom: 4px solid $green;

            @include mq($until: tablet) {
                border-width: 2px;
            }
        }

        &:hover, &.Active {
            background-color: $green;
            color: $black;
        }
    }
}

@import "../scss/framework/tools";

.Main {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        span {
            display: block;

            width: 9px;
            height: 9px;

            border-radius: 100%;
            border: 1px solid $white;

            @include mq($until: tablet) {
                width: 5px;
                height: 5px;
            }
        }



        &:not(:last-child) {
            margin-right: 10px;
        }

        &:global(.active) {
            span {
                background-color: $white;
            }
        }
    }
}

@import "../scss/framework/tools";

.Main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    z-index: 9;

    padding: resp(37) 0 0;

    @include mq($until: tablet) {
        padding: 20px 0 0;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq($until: tablet) {
            justify-content: center;
        }
    }
}

.Container {
    @extend .container;
}

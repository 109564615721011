@import "../scss/framework/tools";

.Main {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;

    h1 {
        z-index: 1;
        padding-bottom: 100px;

        opacity: 0;

        strong {
            -webkit-text-stroke-width: 0;
            color: $white;
        }
    }
}

.Image {
    position: absolute;
    bottom: 0;
    left: -150px;
    @include respSize(max-width, 815);

    opacity: 0;
}

$min-vw: 768px;
$max-vw: 1280px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


@mixin fluid-type($min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {

      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        //font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        font-size: $max-font-size;
      }
    }
  }
}

$max-vw-value: 1440;
@function resp($value){
  @return #{($value*100)/$max-vw-value}vw;
}

@function respM($value){
  @return #{($value*100)/375}vw;
}


$max-vh-value: 960;
@function respH($value) {
  @return #{($value*100)/$max-vh-value}vh;
}

@mixin respSize($prop, $val, $valMobile: $val) {
  #{$prop}: resp($val);

  @media screen and (min-width: 1280px) {
    #{$prop}: #{$val}px;
  }

  @include mq($until: mobile) {
    #{$prop}: respM($valMobile);
  }
}

@import "framework/tools";

html,
body {
    @include mq($until: tablet) {
        position: fixed;
        overflow: hidden;
    }
}

html {
    background-color: $bg;
}

body {
    @extend %DINReg;
    color: $white;
    font-size: resp(16);

    @include mq($until: tablet) {
        width: 100%;
        font-size: 1.6rem;
        line-height: 2.6rem;
        overflow: hidden;
    }

    @include mq($until: small) {
        font-size: 1.4rem;
    }
}

button {
    border: 0;
    outline: 0;
}

figure {
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

[class*="textStroke"] {
    color: transparent;
}

.textStroke {
    -webkit-text-stroke: 2px $white;

    @include mq($until: small) {
        -webkit-text-stroke-width: 1px;
    }

    &--green {
        -webkit-text-stroke: 2px $green;
    }

    &--orange {
        -webkit-text-stroke: 2px $orange;
    }
}

.text {
    &--green {
        color: $green;
    }

    &--orange {
        color: $orange;
    }
}

h1 {
    @extend %DINBold;
    font-size: resp(110);
    line-height: resp(122);

    @include mq($until: tablet) {
        font-size: 6rem;
        line-height: 1;
    }

    @include mq($until: small) {
        font-size: 4rem;
        line-height: 1;
    }
}

[data-rbd-placeholder-context-id] {
    display: none !important;
}

.AnswerTable {
    width: 100%;

    font-size: resp(12);
    line-height: 1.2;
    background-color: rgba(0, 0, 0, .5);

    @include mq($until: tablet) {
        font-size: 1.2rem;
    }

    th {
        @extend %DINBold;
    }

    th, td {
        padding: resp(6) resp(4);

        @include mq($until: tablet) {
            padding: 6px 4px;
        }

        &:not(:first-child) {
            text-align: center;
        }
    }

    tr {
        padding: resp(4) 0;
        border-bottom: 1px solid $white;

        @include mq($until: tablet) {
            padding: 4px 0;
        }
    }
}

@import "../../scss/framework/tools";

.Main {
    display: inline-block;

    padding-left: resp(45);
    padding-right: resp(45);

    background-color: $green;
    color: $black;

    @include mq($until: tablet) {
        position: fixed;
        bottom: 20px;
    }

    &:before {
        background-color: $black;
    }

    &:global(.orange) {
        &:hover {
            color: $orange;
        }
    }

    &:hover {
        color: $green;
    }
}

@import url("https://use.typekit.net/gli3vft.css");

@font-face {
    font-family: 'Gotham-Book';
    src: url('../../fonts/Gotham-Book/Gotham-Book.eot');
    src: url('../../fonts/Gotham-Book/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Gotham-Book/Gotham-Book.woff') format('woff'),
    url('../../fonts/Gotham-Book/Gotham-Book.ttf')  format('truetype'),
    url('../../fonts/Gotham-Book/Gotham-Book.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Gotham-Bold';
    src: url('../../fonts/Gotham-Bold/Gotham-Bold.eot');
    src: url('../../fonts/Gotham-Bold/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Gotham-Bold/Gotham-Bold.woff') format('woff'),
    url('../../fonts/Gotham-Bold/Gotham-Bold.ttf')  format('truetype'),
    url('../../fonts/Gotham-Bold/Gotham-Bold.svg#svgFontName') format('svg');
    font-weight: normal;
    font-style: normal;
}


%DINIt {
    font-family: din-2014,sans-serif;
    font-weight: 400;
    font-style: italic;
}

%DINReg {
    font-family: din-2014,sans-serif;
    font-weight: 400;
    font-style: normal;
}

%DINBold {
    font-family: din-2014,sans-serif;
    font-weight: 700;
    font-style: normal;
}

%DINBoldIt {
    font-family: din-2014,sans-serif;
    font-weight: 700;
    font-style: italic;
}

%GothamReg {
    font-family: 'Gotham-Book', sans-serif;
}

%GothamBold {
    font-family: 'Gotham-Bold', sans-serif;
}
